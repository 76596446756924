import {Empty, Table as AntTable, TableProps} from 'antd';
import React from "react";
import LocaleText from "../../app/settings/dictionary/LocaleText";
import Button from "../button/Button";
import {CloseOutlined} from "@ant-design/icons";


interface IProps extends TableProps<any> {
    resetFilters?: () => void
}

class Table extends React.Component<IProps> {
    render() {
        const {resetFilters, ...props} = this.props

        return (
            <AntTable
                {...props}
                locale={{
                    emptyText: <Empty className={'m-2 font-size-sm'} description={<LocaleText code={'table.empty'} fallback={'Žádné výsledky'}/>} image={Empty.PRESENTED_IMAGE_SIMPLE}>
                        <Button type={"primary"} size={"small"} className={"mt-2"} onClick={resetFilters} icon={<CloseOutlined/>}><LocaleText code={'table.filter.clear'} fallback={'Zrušit filtry'}/></Button>
                    </Empty>
                }}
            />
        )
    }
}

export default Table